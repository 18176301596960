
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Inicio',

    metaInfo: {
      title: 'Inicio',
      meta: [
        { name: 'description', content: 'La calidad de todos sus productos, el servicio a sus clientes y una elaboración artesana son las características que diferencian a Cantdelimar como una empresa sólida que apuesta por ofrecer un producto que destaque por su incomparable sabor y calidad, así como por una excelente presentación.' },
        { name: 'keywords', content: 'Cantdelimar,pudin de pescado,txangurro,pudin,cantabria,gourmet' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'hero',
        'theme-features',
        'contact-us',
        'keep-in-touch',
        'newsletter',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'inicio',
      },
    },
  }
</script>
